import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import SiteMetadata from "../components/SiteMetadata"
import Button from "../components/Button"
// import Cards from "../components/Cards"
// import Carousel from "../components/Carousel"
import Newsletter from "../components/Newsletter"
import Layout from "../layouts/Layout"


const ArticleItem = (props) => {
  const {
    description,
    image,
    titre,
    // categorie,
    // sousCategorie,
    // slug,
    reference,
    prix
  } = props.data.item

  return (
    <Layout>
      <SiteMetadata
        title={titre}
        description={description.description}
        />
        {/* image={image.localFile.childImageSharp.fluid} */}
      <div className="bg-gray-0 py-12 lg:py-16">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full lg:w-2/3 pb-8"
        style={{
          // // By using the same grid area for both, they are stacked on top of each other
          // gridArea: "1/1",
          // position: "relative",
          // // This centers the other elements inside the hero component
          // placeItems: "center",
          // display: "grid",
        }}>
        {/* {image && image.localFile && image.localFile.childImageSharp && (              
            <Img
              // fluid={image.localFile.childImageSharp.fluid}
              fixed={image.localFile.childImageSharp.fixed}
              width="60px"
              alt={titre}
            />
        )} */}
        {image && image.localFile && image.localFile.childImageSharp && (              
            <Img
              // fluid={{...image.localFile}}
              // fluid={image.localFile.childImageSharp.fluid}
              fixed={image.localFile.childImageSharp.fixed}
              // width="60px"
              // height="100%"
              imgStyle={{objectFit: 'contain'}}
              alt={titre}
            />
        )}
            </div>
            <div className="w-full lg:w-1/3 lg:pl-8 xl:pl-12">
              <h1 className="text-3xl leading-tight font-extrabold tracking-tight text-gray-900 sm:text-4xl mb-1">
                {titre}
              </h1>
              <h2 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                {prix} €
              </h2>
              {description && (
                <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                  {description.description}
                </div>
              )}
              {image && (
                <div className="mt-8">
                  <Button href={'mailto:contact@maisondelevenementiel.fr?subject=Commande - '+reference+' - '+titre}>Commander</Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query ArticlesItemQUery($slug: String!) {
    item: contentfulArticles(slug: { eq: $slug }) {
      description {
        description
      }
      titre
      categorie
      prix
      slug
      reference
      image {
        localFile {
          publicURL
          childImageSharp {
            fluid(maxHeight: 600, quality: 85) {
              ...GatsbyImageSharpFluid
            }
            fixed(width: 600, quality: 85) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      reference
    }
  }
`
export default ArticleItem;